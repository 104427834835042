.Page {
  display: grid;
  // grid-template-areas: "header" "main" "feedback" "footer";
  // grid-template-rows: 3.0625rem 1fr auto 11.1rem;
  grid-template-areas: "header" "main" "footer";
  grid-template-rows: 3.0625rem 1fr auto;
  min-height: 100vh;
  background: $lightest-grey;

  @include media-tablet {
    // grid-template-rows: 4.875rem 1fr auto 5.2rem;
    grid-template-rows: 4.875rem 1fr auto;
  }

  &__main {
    grid-area: main;
    display: grid;
    grid-template-areas: "left content";
    grid-template-columns: auto 1fr;
  }

  &__section {
    grid-area: content;
    display: grid;
    grid-template-areas: "right" "center";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto 1fr;
    position: relative;

    @include media-desktop {
      grid-template-rows: auto;
      grid-template-areas: "center right";
    }
  }

  &__aside {
    &-left {
      grid-area: left;
    }

    &-right {
      grid-area: right;
    }
  }

  &__content {
    grid-area: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &_middle {
      @extend .Page__content;
      align-self: center;
    }

    &-center {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      max-width: 100rem;
      margin: 0 auto;
      padding: 2.5rem 1.25rem;

      @include media-tablet {
        padding: 2.5rem 1.875rem;
      }

      @include media-desktop {
        padding: 2.5rem 5rem;
      }
    }
  }

  &__netw {
    .Page__content-center {
      @include media-desktop {
        padding: 2.5rem;
      }
    }
  }
}

.swiper-container {
  width: 100%;
}
