// $blue: #2b446a;

$dark-red: #ee2211;

// $white: #ffffff;
$lightest-grey: #f5f5f5;
// $light-grey: #eeeeee;
// $grey: #c6c6c6;
// $dark-grey: #808080;
$grey-f0: #f0f0f0;
$grey-f2: #f2f2f2;
$grey-cc: #ccc;
$grey-c0: #c0c0c0;
$grey-80: #808080;
$grey-60: #606060;
$red-f21: #f21;
$darkest-grey: #222222;


$blue: #4D00FF;
$red: #ff2211;
$green: #B2FF00;
$black: #191919;
$white: #ffffff;
$light-grey: #EBEBEB;
$grey: #D9D9D9;
$dark-grey: #8E8E8E;
$grey-e5: #e5e5e5;
$grey-c6: #c6c6c6;