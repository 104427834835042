// heading mixins

@mixin h96 {
  font-size: 6em;
  line-height: 0.9;
  font-weight: 700; }

@mixin h64 {
  font-size: 4em;
  line-height: 1;
  font-weight: 700; }

// @mixin h48
//   font-size: 3em
//   line-height: 1.1
//   font-weight: 700

// @mixin h36
//   font-size: 2.25em
//   line-height: 1.2
//   font-weight: 700

@mixin h24 {
  font-size: 1.5em;
  line-height: 1.25;
  font-weight: 700; }

// regular blocks mixins

@mixin fs24 {
  font-size: 1.5em;
  line-height: 1.25; }

@mixin fs18 {
  font-size: 1.125em;
  line-height: 1.25; }

// @mixin fs16
//   font-size: 1em
//   line-height: 1.3

// @mixin fs14
//   font-size: 0.875em
//   line-height: 1.3

@mixin fs11 {
  font-size: 0.6875em;
  line-height: 1.35; }

// paragraph mixins

@mixin p18 {
  font-size: 1.125em;
  line-height: 1.5; }

@mixin p16 {
  font-size: 1em;
  line-height: 1.55; }

@mixin p14 {
  font-size: 0.875em;
  line-height: 1.55; }

@mixin p11 {
  font-size: 0.6875em;
  line-height: 1.6; }

// heading mixins new

@mixin h48 {
  font-size: 3em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h40 {
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h36 {
  font-size: 2.25em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h32 {
  font-size: 2em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h28 {
  font-size: 1.75em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h24 {
  font-size: 1.5em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h20 {
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 800; }

@mixin h18 {
  font-size: 1.125em;
  line-height: 1.2;
  font-weight: 800; }

// regular blocks mixins

@mixin fs36 {
  font-size: 2.25em;
  line-height: 1.2; }

@mixin fs28 {
  font-size: 1.75em;
  line-height: 1.2; }

@mixin fs24 {
  font-size: 1.5em;
  line-height: 1.2; }

@mixin fs20 {
  font-size: 1.25em;
  line-height: 1.2; }

@mixin fs16 {
  font-size: 1em;
  line-height: 1.2; }

@mixin fs14 {
  font-size: 0.875em;
  line-height: 1.2; }

@mixin fs13 {
  font-size: 0.8125rem;
  line-height: 1.2; }

@mixin fs12 {
  font-size: 0.75em;
  line-height: 1.2; }

@mixin fs11 {
  font-size: 0.6875em;
  line-height: 1.2; }

@mixin fw400 {
  font-weight: 400; }

@mixin fw600 {
  font-weight: 600; }

@mixin fw700 {
  font-weight: 700; }
