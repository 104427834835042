@font-face {
  font-family: "Golos";
  font-weight: 400;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-Regular.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Regular.woff") format("woff");
}

@font-face {
  font-family: "Golos";
  font-weight: 500;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-Medium.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Medium.woff") format("woff");
}

@font-face {
  font-family: "Golos";
  font-weight: 600;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-DemiBold.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-DemiBold.woff") format("woff");
}

@font-face {
  font-family: "Golos";
  font-weight: 700;
  font-style: normal;
  src: url("/public/fonts/golos/GolosText-Bold.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Bold.woff") format("woff");
}

@font-face {
  font-family: "Golos";
  font-style: normal;
  font-weight: 800;
  src: url("/public/fonts/golos/GolosText-Black.woff2") format("woff2"),
    url("/public/fonts/golos/GolosText-Black.woff") format("woff");
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-LightIt.eot");
  src: local("Proxima Nova Light Italic"), local("ProximaNova-LightIt"),
    url("/public/fonts/proxima/ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-LightIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-LightIt.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-Extrabld.eot");
  src: local("Proxima Nova Extrabold"), local("ProximaNova-Extrabld"),
    url("/public/fonts/proxima/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-Extrabld.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-Extrabld.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-Regular.eot");
  src: local("Proxima Nova Regular"), local("ProximaNova-Regular"),
    url("/public/fonts/proxima/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-Regular.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-Semibold.eot");
  src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"),
    url("/public/fonts/proxima/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-Semibold.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-ThinIt.eot");
  src: local("Proxima Nova Thin Italic"), local("ProximaNova-ThinIt"),
    url("/public/fonts/proxima/ProximaNova-ThinIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-ThinIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-ThinIt.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-SemiboldIt.eot");
  src: local("Proxima Nova Semibold Italic"), local("ProximaNova-SemiboldIt"),
    url("/public/fonts/proxima/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-SemiboldIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-RegularIt.eot");
  src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"),
    url("/public/fonts/proxima/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-RegularIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-Black.eot");
  src: local("Proxima Nova Black"), local("ProximaNova-Black"),
    url("/public/fonts/proxima/ProximaNova-Black.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-Black.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-Bold.eot");
  src: local("Proxima Nova Bold"), local("ProximaNova-Bold"),
    url("/public/fonts/proxima/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-Bold.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNovaT-Thin.eot");
  src: local("Proxima Nova Thin"), local("ProximaNovaT-Thin"),
    url("/public/fonts/proxima/ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNovaT-Thin.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNovaT-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-BoldIt.eot");
  src: local("Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"),
    url("/public/fonts/proxima/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-BoldIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-ExtrabldIt.eot");
  src: local("Proxima Nova Extrabold Italic"), local("ProximaNova-ExtrabldIt"),
    url("/public/fonts/proxima/ProximaNova-ExtrabldIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-ExtrabldIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-ExtrabldIt.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-Light.eot");
  src: local("Proxima Nova Light"), local("ProximaNova-Light"),
    url("/public/fonts/proxima/ProximaNova-Light.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-Light.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Proxima Nova';
  src: url("/public/fonts/proxima/ProximaNova-BlackIt.eot");
  src: local("Proxima Nova Black Italic"), local("ProximaNova-BlackIt"),
    url("/public/fonts/proxima/ProximaNova-BlackIt.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/proxima/ProximaNova-BlackIt.woff") format("woff"),
    url("/public/fonts/proxima/ProximaNova-BlackIt.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url("/public/fonts/bebas/BebasNeue-Regular.eot");
  src: local("Proxima Nova Regular"), local("BebasNeue-Regular"),
    url("/public/fonts/bebas/BebasNeue-Regular.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/bebas/BebasNeue-Regular.woff") format("woff"),
    url("/public/fonts/bebas/BebasNeue-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url("/public/fonts/bebas/BebasNeue-Bold.eot");
  src: local("Proxima Nova Bold"), local("BebasNeue-Bold"),
    url("/public/fonts/bebas/BebasNeue-Bold.eot?#iefix") format("embedded-opentype"),
    url("/public/fonts/bebas/BebasNeue-Bold.woff") format("woff"),
    url("/public/fonts/bebas/BebasNeue-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
