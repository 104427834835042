@import "../variables/index";
@import "../mixins/index";
@import "fonts";
@import "grid";
@import "container";

* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-family: $base-font;
  font-size: 16px;
  line-height: 1.3;
  scroll-padding-top: 4.875em;
}

body {
  min-width: $mobile-width;
  margin: 0;
  height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.react-datepicker {
  &-wrapper {
    width: 100%;
  }

  &__tab-loop {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__input-container {
    &>input[type="text"] {
      width: 100%;
      padding: 9px 39px 9px 11px;
      font-size: 14px;
      line-height: 1.3;
      background: transparent url("@public/images/icons/datepicker-icon.svg") calc(100% - 11px) center / 18px 18px no-repeat;
      border: none;

      &::-webkit-input-placeholder {
        color: $dark-grey;
      }

      &::-moz-placeholder {
        color: $dark-grey;
      }

      &:-moz-placeholder {
        color: $dark-grey;
      }

      &:-ms-input-placeholder {
        color: $dark-grey;
      }
    }
  }
}

div,
a,
nav,
ul,
li {
  box-sizing: border-box;
  transition: 0.3s;
}

.company-user-avatar-fix {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
}

.tac {
  text-align: center;
}

#root {
  white-space: pre-line;
}

img {
  max-width: 100%;
  font-size: 1em;
}

a {
  text-decoration: none;
  color: $black;
}

h2 {
  @include reset;
  @include h48;
}

h3 {
  @include reset;
  @include h36;
}

#userBar {
  display: none;
}

#app,
#root {
  min-height: 100vh;
}

.langing {
  min-height: 100vh;
}

input[type="text"] {
  @include fs14;
  border: 1px solid $grey-e5;
}

.visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}

.text-red {
  color: $red;
}

#jaas-container {
  height: 100%;
}

.schedule-experts {
  .swiper-slide {
    width: auto !important;
  }

  .swiper-button-disabled {
    visibility: hidden;
  }
}

.form hr {
  height: 1px;
  margin: 0;
  border: none;
  background-color: #888;
}

.input-dd {
  position: relative;

  &--close {
    input {
      border-bottom: 1px solid $grey-e5;
    }

    .input-dd__main {
      height: 0;
      padding: 0;
      overflow: hidden;
      visibility: hidden;
    }

    .input-dd__trigger {
      border-radius: 1.25rem;
      border-color: $grey-e5;

      @include media-tablet {
        border-radius: 1.875rem;
      }  
    }
    
    .input-dd__trigger--error {
      border-color: $red;
    }
  }

  &__trigger {
    @include fs14;
    position: relative;
    display: block;
    // display: flex;
    align-items: center;
    height: auto;
    min-height: 2.7143em;
    padding: 0.5714em 0.8571em;
    padding-right: 3em;
    z-index: 10;
    cursor: pointer;
    border: 1px solid $grey-e5;
    border-bottom: 1px solid $white;
    background-color: $white;
    color: $dark-grey;
    border-radius: 1.25rem 1.25rem 0 0;

    &::after {
      content: "";
      position: absolute;
      right: 1em;
      top: 50%;
      width: 10px;
      height: 7px;
      transform: translateY(-50%);
      background-image: url("../../images/dropdown-arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    span {
      color: $black;
    }
  }

  &__main {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;
    padding: 1.5em;
    max-height: 32em;
    background-color: #ffffff;
    box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.15);
    overflow-y: scroll;
    transition: none;

    label {
      @include fs14;
      display: inline-block;
      padding: 0.5714em 0;
      user-select: none;
      cursor: pointer;
    }

    input:checked+label {
      color: $blue;
    }
  }

  &__group {
    margin: 0.5em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-title {
      @include fs14;
      margin-bottom: 0.5714em;
      font-weight: 800;
    }
  }
}

.input {
  &--error {
    border-color: $red !important;
  }
}

.textarea {
  position: relative;
  width: 100%;

  textarea,
  &__input {
    @include fs14;
    border: 1px solid $grey-e5;
    height: auto;
    width: 100%;
    min-height: 2.7143em;
    resize: none;
    padding: 0.8571em;
    padding-bottom: 1.5em;
    font-family: $base-font;
  }

  &__counter {
    @include fs11;
    position: absolute;
    right: 1.9em;
    bottom: 1em;
    color: $grey-c0;
  }
}

textarea {
  &::-webkit-input-placeholder {
    color: $dark-grey;
  }

  &::-moz-placeholder {
    color: $dark-grey;
  }

  &:-moz-placeholder {
    color: $dark-grey;
  }

  &:-ms-input-placeholder {
    color: $dark-grey;
  }
}

.auth__dropdown-group {
  margin: 0.5em 0;

  &-title {
    @include fs14;
    margin-bottom: 0.5714em;
    font-weight: 800;
  }
}

.input[type="text"],
.input[type="email"],
.input[type="password"],
.input[type="number"],
.input[type="phone"] {
  font-size: 0.875em;
  line-height: 1.55;
  height: 2.375rem;
  width: 100%;
  padding: 0 0.8571rem;
  border-radius: 1.25rem;
  border: 1px solid #e5e5e5;
  appearance: none;

  @include media-tablet {
    border-radius: 1.875rem;
  }

  &::-webkit-input-placeholder {
    color: $dark-grey;
  }

  &::-moz-placeholder {
    color: $dark-grey;
  }

  &:-moz-placeholder {
    color: $dark-grey;
  }

  &:-ms-input-placeholder {
    color: $dark-grey;
  }

  // &.input--error {
  //   border-color: $blue;
  // }
}

// .input {
//   &--error {
//     border-color: $blue;
//   }
// }

.react-tel-input {
  .flag-dropdown {
    border-radius: 1.25rem 0 0 1.25rem !important;

    @include media-tablet {
      border-radius: 1.875rem 0 0 1.875rem !important;
    }
  }

  .invalid-number {
    background-color: transparent !important;

    &.form-control:focus {
      border: 0 !important;
    }
  }
}
